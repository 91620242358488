import { config } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import PropTypes from 'prop-types';

import '@fortawesome/fontawesome-svg-core/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import { App } from '@context';
import '@styles/base.scss';

// Disable autoAddCss to help Fontawesome Icons to be reasonable sizes
config.autoAddCss = false;

export const wrapRootElement = ({ element }) => (
  <App.Provider>
    {element}
  </App.Provider>
);

wrapRootElement.propTypes = {
  element: PropTypes.element.isRequired,
};
