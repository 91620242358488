import React, { createContext, useReducer } from 'react';

export interface IAppContext {
  dispatch: React.Dispatch<Action>;
  state: IAppState;
}

interface IAppState {
  cheatCodesEntered: string[];
  cheatCodesModalOpen: boolean;
}

type Action =
  | { type: Actions.PUSH_CC, payload: string }
  | { type: Actions.TOGGLE_CC_MODAL, payload: boolean };

enum Actions {
  PUSH_CC = 'PUSH_CC',
  TOGGLE_CC_MODAL = 'TOGGLE_CC_MODAL',
}

const AppContext = createContext({} as IAppContext);

const defaultAppValues = {
  cheatCodesEntered: [],
  cheatCodesModalOpen: false,
};

const Reducer = (state: IAppState, action: Action): IAppState => {
  switch (action.type) {
    case Actions.PUSH_CC:
      return {
        ...state,
        cheatCodesEntered: [...state.cheatCodesEntered, action.payload],
      };
    case Actions.TOGGLE_CC_MODAL:
      return {
        ...state,
        cheatCodesModalOpen: action.payload,
      };
    default:
      return state;
  }
};

const Provider = ({ children }: any) => {
  const [state, dispatch] = useReducer(Reducer, defaultAppValues);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export default {
  Actions,
  Context: AppContext,
  Provider,
  Reducer,
  defaultAppValues,
};
